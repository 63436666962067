
import { Options, Vue } from 'vue-class-component';
import ImageView from './components/ImageView.vue';
import Tutorial from './components/Tutorial.vue';
import AppHeader from './components/Header.vue'
import AnnotationStore, { Annotation } from './services/annotationStore';
import { reactive } from 'vue';
import Answer from './models/Answer';

@Options({
  components: {
    ImageView,
    AppHeader,
    Tutorial
  },
})
export default class App extends Vue {
  isTutorialVisible: boolean = false;
  isImageLoaded: boolean = false;
  reactiveAnnos: Annotation[] = reactive([]);
  reactiveParents: string[] = reactive([]);
  store = new AnnotationStore(['Figure', 'Table'], ['Caption'], undefined, this.reactiveParents);
  private urlParams = new URLSearchParams(window.location.search);
  assignmentLoaded: boolean = false;
  answer = null;
  assignment = null;
  assignmentUrl = null;
  // Placeholder value for the MTurk assignment id
  private assignmentId = 'ASSIGNMENT_ID_NOT_AVAILABLE';

  async beforeMount() {
    this.assignmentUrl = this.urlParams.get('assignmentUrl');
    // Get assignment id from URL params
    let idParam = this.urlParams.get('assignmentId');
    if (this.assignmentUrl) {
      this.assignmentId = 'ADMIN_ASSIGNMENT';
    } else if (idParam) {
      this.assignmentId = idParam;
    }
    if (this.assignmentUrl) {
      fetch(this.assignmentUrl)
        .then(async response => {
          let parsed = await response.json();
          let fetchedAnswer = Object.assign(new Answer(), parsed.answer);
          this.answer = fetchedAnswer;
          this.assignment = parsed;
        })
        .catch(err => {
          console.log(`Error fetching annotations: ${err}`)
        })
        .finally(() => {
          this.assignmentLoaded = true;
        })
    } else {
      this.assignmentLoaded = true;
    }
  }

  toggleTutorial() {
    this.isTutorialVisible = !this.isTutorialVisible;
  }

  imageLoaded() {
    this.isImageLoaded = true;
  }
}
