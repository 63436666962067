
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    scrollTimeoutMs: Number,
  },
  emits: ["scroll"],
})
export default class ScrollEdges extends Vue {
  private activeSides: string[];
  private scrollTimeoutMs: number;
  private scrollingActive: boolean;
  private inFrame = true;

  mounted() {
    if (this.activeSides) {
      this.activeSides.length = 0;
    } else {
      this.activeSides = [];
    }
    this.scrollingActive = false;
  }

  unmounted() {
    this.activeSides.length = 0;
  }

  private outerEnter(id: string) {
    this.addSide(id);
  }

  private outerLeave(id: string) {
    // Slight timeout to allow for leaving frame and still keep scrolling
    setTimeout(() => {
      this.removeSide(id);
    }, this.scrollTimeoutMs);
  }

  private addSide(side: string) {
    if (!this.activeSides.includes(side)) {
      this.activeSides.push(side);
      if (!this.scrollingActive) this.emitScroll();
    }
  }

  private removeSide(side: string) {
    if (this.inFrame) {
      let index = this.activeSides.indexOf(side);
      if (index > -1) {
        this.activeSides.splice(index, 1);
      }
    }
  }

  public frameLeave() {
    this.inFrame = false;
  }

  public frameEnter() {
    this.inFrame = true;
    // JS sometimes skips frames and ignores edgeLeave so this is needed
    this.activeSides.length = 0;
  }

  private async emitScroll() {
    this.scrollingActive = true;
    while (this.activeSides.length) {
      await new Promise((resolve) => {
        setTimeout(() => {
          if (this.activeSides.length) {
            this.$emit("scroll", this.activeSides);
          }
          resolve(true);
        }, this.scrollTimeoutMs);
      });
    }
    this.scrollingActive = false;
  }
}
