import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "left-edge",
      ref: "left",
      class: "edge",
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.outerEnter($event.target.id))),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.outerLeave($event.target.id)))
    }, null, 544),
    _createElementVNode("div", {
      id: "left-bottom-corner",
      ref: "leftBottom",
      class: "edge corner",
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.outerEnter('left-edge');
        _ctx.outerEnter('bottom-edge');
      }),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => {
        _ctx.outerLeave('left-edge');
        _ctx.outerLeave('bottom-edge');
      })
    }, null, 544),
    _createElementVNode("div", {
      id: "left-top-corner",
      ref: "leftTop",
      class: "edge corner",
      onMouseenter: _cache[4] || (_cache[4] = ($event: any) => {
        _ctx.outerEnter('left-edge');
        _ctx.outerEnter('top-edge');
      }),
      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => {
        _ctx.outerLeave('left-edge');
        _ctx.outerLeave('top-edge');
      })
    }, null, 544),
    _createElementVNode("div", {
      id: "top-edge",
      ref: "top",
      class: "edge",
      onMouseenter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.outerEnter($event.target.id))),
      onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.outerLeave($event.target.id)))
    }, null, 544),
    _createElementVNode("div", {
      id: "right-edge",
      ref: "right",
      class: "edge",
      onMouseenter: _cache[8] || (_cache[8] = ($event: any) => (_ctx.outerEnter($event.target.id))),
      onMouseleave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.outerLeave($event.target.id)))
    }, null, 544),
    _createElementVNode("div", {
      id: "right-bottom-corner",
      ref: "rightBottom",
      class: "edge corner",
      onMouseenter: _cache[10] || (_cache[10] = ($event: any) => {
        _ctx.outerEnter('right-edge');
        _ctx.outerEnter('bottom-edge');
      }),
      onMouseleave: _cache[11] || (_cache[11] = ($event: any) => {
        _ctx.outerLeave('right-edge');
        _ctx.outerLeave('bottom-edge');
      })
    }, null, 544),
    _createElementVNode("div", {
      id: "right-top-corner",
      ref: "rightTop",
      class: "edge corner",
      onMouseenter: _cache[12] || (_cache[12] = ($event: any) => {
        _ctx.outerEnter('right-edge');
        _ctx.outerEnter('top-edge');
      }),
      onMouseleave: _cache[13] || (_cache[13] = ($event: any) => {
        _ctx.outerLeave('right-edge');
        _ctx.outerLeave('top-edge');
      })
    }, null, 544),
    _createElementVNode("div", {
      id: "bottom-edge",
      ref: "bottom",
      class: "edge",
      onMouseenter: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.outerEnter($event.target.id)), ["stop"])),
      onMouseleave: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.outerLeave($event.target.id)), ["stop"]))
    }, null, 544)
  ], 64))
}