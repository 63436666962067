
import AnnotationStore from '@/services/annotationStore';
import FeedbackPopup from './FeedbackPopup.vue';
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import TutorialTooltip from './TutorialTooltip.vue';
import YesNoModal from './YesNoModal.vue';

@Options({
    components: {
        FeedbackPopup,
        TutorialTooltip,
        YesNoModal
    }
})
export default class AppHeader extends Vue {
    @Prop()
    private annotationStore: AnnotationStore;
    @Prop()
    private isTutorialVisible: boolean;
    @Prop()
    private isImageLoaded: boolean;
    @Prop()
    private assignment: any;
    @Prop()
    private assignmentUrl: string;
    @Prop()
    private assignmentId: string;

    private counter = 0;
    private urlParams = new URLSearchParams(window.location.search);
    // Injected from package.json
    private appVersion = process.env.VUE_APP_VERSION;
    private majorVersion = this.appVersion.match(/\d+/)[0];
    private SEEN_TUTORIAL_KEY = `${this.majorVersion}_seenTutorial`;
    private ACCEPTED_NO_REF_KEY = `${this.majorVersion}_acceptedNoRef`;
    
    // Placeholder value for the MTurk submit link
    private submitLink = 'https://webhook.site/9c353bcf-91aa-4d88-96f3-93c351b9562f';
    // Parsed from query params, if it exists
    private comment = '';
    private csrfToken = null;
    private acceptEmpty: boolean = false;
    private acceptOrphans: boolean = false;
    private showFeedbackModal = false;
    private showYesNoModal = false;

    // Saved in local storage
    private tutorialSeen = false;
    private acceptedNoRef = false;

    private submit(e: Event) {
        if (this.acceptOrphans && !this.acceptedNoRef) {
            this.showYesNoModal = true;
            e.preventDefault();
        }
        
    }

    mounted() {
        // Start counter
        setInterval(() => {
            this.counter++
        }, 1000)

        // Get version
        console.log(`App version: ${this.appVersion}`);

        //if (this.assignmentId == 'ASSIGNMENT_ID_NOT_AVAILABLE') this.$emit('toggle-tutorial');
        this.tutorialSeen = !!localStorage.getItem(this.SEEN_TUTORIAL_KEY) || this.assignmentId != 'ASSIGNMENT_ID_NOT_AVAILABLE';

        // Get submit link from URL params
        if (this.assignmentUrl) {
            this.submitLink = this.assignmentUrl;
        } 
        let submitParam = this.urlParams.get('turkSubmitTo');
        if (submitParam) {
            this.submitLink = submitParam + '/mturk/externalSubmit';
        }

        let commentParam = this.urlParams.get('comment');
        if (commentParam) this.comment = commentParam;

        let csrfParam = this.urlParams.get('csrf_token');
        if (csrfParam) {
            console.log('Loaded CSRF!');
            this.csrfToken = csrfParam;
        }

        this.acceptedNoRef = !!localStorage.getItem(this.ACCEPTED_NO_REF_KEY);
    }

    annotationsEmpty() {
        return this.annotationStore.annotations.length == 0;
    }

    orphansOrChildless() {
        return this.annotationStore.nrOrphans != 0 || this.annotationStore.freeParents.length != 0;
    }

    submitEnabled() {
        return this.assignmentId != 'ASSIGNMENT_ID_NOT_AVAILABLE' 
        && this.isImageLoaded
        && (!this.annotationsEmpty() || this.acceptEmpty)
        && (!this.orphansOrChildless() || this.acceptOrphans);
    }

    submitForm() {
        if (this.acceptOrphans) {
            this.acceptedNoRef = true;
            localStorage.setItem(this.ACCEPTED_NO_REF_KEY, 'true');
        }
        (this.$refs.submitButton as any).click();
    }

    toggleTutorial() {
        this.$emit('toggle-tutorial');
        localStorage.setItem(this.SEEN_TUTORIAL_KEY, 'true');
        this.tutorialSeen = true;
    }
}

